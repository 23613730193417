import axios from 'axios';
import { collapse, expand } from './utils/iframeExpander';
import { appendFrame } from './utils/appendFrame';
import {
  PLAYER_RESIZED,
  PLAYER_MOUNTED,
  PLAYER_INITIALIZE,
  PLAYER_PLAY,
  PLAYER_PAUSE,
  PLAYER_PAUSED,
  PLAYER_PLAYED,
  PLAYER_EVENT,
  PLAYER_CLOSE,
  PLAYER_PLAY_FORBIDDEN,
  PLAYER_NAVIGATE,
  PLAYER_TRACK,
  PLAYER_ENDED,
  PLAYER_RESTARTED,
} from './utils/messages';

const playerIds = new Set();

async function requestAxiosConfig(config) {
  if (typeof config === 'string') {
    return axios.get(config);
  }

  return Promise.resolve(
    {
      data: config,
    },
  );
}

const sendMessage = async (target, type, data = {}) => {
  (await target).contentWindow.postMessage({
    type,
    data,
  }, '*');
};

class FazPlayer {
  constructor(selector) {
    this.selector = selector;
    this.playerId = null;
    this.target = null;
    this.isHidden = false;
  }

  async initPlayer(selector) {
    this.target = appendFrame(selector);
    window.addEventListener('message', async (message) => {
      if (message.data.data) {
        const id = message.data.data.playerId;
        switch (message.data.type) {
          case PLAYER_MOUNTED:
            if (this.playerId === null && !playerIds.has(id)) {
              playerIds.add(id);
              this.playerId = id;
            }
            break;
          case PLAYER_CLOSE:
            if (id === this.playerId) {
              collapse(await this.target);
              this.isHidden = true;
              document.dispatchEvent(new CustomEvent(PLAYER_EVENT, {
                detail: {
                  playerId: id,
                  type: 'close',
                },
              }));
            }
            break;
          case PLAYER_RESIZED:
            if (id === this.playerId) {
              (await this.target).height = message.data.data.height;
            }
            break;
          case PLAYER_PAUSED:
            if (id === this.playerId) {
              console.log(message.data);
              document.dispatchEvent(new CustomEvent(PLAYER_EVENT, {
                detail: {
                  playerId: id,
                  type: 'pause',
                  playtime: message.data.data.playtime,
                  remainingTime: message.data.data.remainingTime,
                },
              }));
            }
            break;
          case PLAYER_PLAYED:
          case PLAYER_RESTARTED:
            if (id === this.playerId) {
              document.dispatchEvent(new CustomEvent(PLAYER_EVENT, {
                detail: {
                  playerId: id,
                  type: 'play',
                },
              }));
            }
            break;
          case PLAYER_ENDED:
            if (id === this.playerId) {
              document.dispatchEvent(new CustomEvent(PLAYER_EVENT, {
                detail: {
                  playerId: id,
                  type: 'end',
                  duration: message.data.data.duration,
                },
              }));
            }
            break;
          case PLAYER_PLAY_FORBIDDEN:
            if (id === this.playerId) {
              const event = new Event(PLAYER_PLAY_FORBIDDEN);
              document.dispatchEvent(event);
            }
            break;
          case PLAYER_NAVIGATE:
            console.debug('Received navigate event: ', message);
            if (!message.data.data) {
              console.warn('Received event "PLAYER_NAVIGATE" without target path. Aborting.');
              break;
            }
            window.location.pathname = message.data.data;
            break;
          case PLAYER_TRACK:
            if (id === this.playerId) {
              document.dispatchEvent(new CustomEvent(PLAYER_EVENT, {
                detail: {
                  playerId: id,
                  type: 'track',
                  eventName: message.data.data.eventName,
                  eventDetail: message.data.data.eventDetail,
                },
              }));
            }
            break;
          default:
            break;
        }
      }
    });
    await this.target;
  }

  async loadConfig(config, customOptions) {
    const playerConfig = await requestAxiosConfig(config);
    const data = {
      ...playerConfig.data,
      customOptions,
    };
    sendMessage(this.target, PLAYER_INITIALIZE, data);
    return data;
  }

  async play() {
    sendMessage(this.target, PLAYER_PLAY);
  }

  async pause() {
    sendMessage(this.target, PLAYER_PAUSE);
  }

  async open() {
    if (this.isHidden) {
      expand(await this.target);
      this.isHidden = false;
    }
  }

  async close() {
    if (!this.isHidden) {
      collapse(await this.target);
      this.isHidden = true;
    }
  }
}

const initPlayer = async (selector) => {
  const player = new FazPlayer(selector);
  await player.initPlayer(selector);
  return player;
};

window.fazPlayer = {
  initPlayer,
  getVersion: () => process.env.PLAYER_VERSION,
};
