export const PLAYER_INITIALIZE = 'FAZ:PLAYER:INITIALIZE';
export const PLAYER_OPEN = 'FAZ:PLAYER:OPEN';
export const PLAYER_PLAY = 'FAZ:PLAYER:PLAY';
export const PLAYER_PAUSE = 'FAZ:PLAYER:PAUSE';
export const PLAYER_CLOSE = 'FAZ:PLAYER:CLOSE';
export const PLAYER_NAVIGATE = 'FAZ:PLAYER:NAVIGATE';
// events
export const PLAYER_EVENT = 'FAZ:PLAYER:EVENT';
export const PLAYER_RESIZED = 'FAZ:PLAYER:RESIZED';
export const PLAYER_MOUNTED = 'FAZ:PLAYER:MOUNTED';
export const PLAYER_PLAYED = 'FAZ:PLAYER:PLAYED';
export const PLAYER_RESTARTED = 'FAZ:PLAYER:RESTART';
export const PLAYER_ENDED = 'FAZ:PLAYER:ENDED';
export const PLAYER_PAUSED = 'FAZ:PLAYER:PAUSED';
export const PLAYER_CONFIG_RECEIVED = 'FAZ:PLAYER:CONFIG_RECEIVED';
export const PLAYER_PLAY_FORBIDDEN = 'FAZ:PLAYER:FORBIDDEN';
export const PLAYER_TRACK = 'FAZ:PLAYER:TRACK';
