import { findNode } from '@/utils/dom';

const frameUrl = ['production', 'qa', 'ew'].includes(process.env.VUE_APP_MODE)
  ? process.env.VUE_APP_FRAME_URL : 'http://localhost:9080/';

export const appendFrame = (selector) => {
  const parent = findNode(selector);

  const frame = document.createElement('iframe');
  frame.setAttribute('src', frameUrl);
  frame.setAttribute('sandbox', 'allow-forms allow-modals allow-pointer-lock allow-popups allow-same-origin allow-scripts');
  frame.setAttribute('style', 'width: 100%; border: 0; display: block;');
  frame.setAttribute('allow', 'autoplay');
  frame.height = '49px';
  parent.appendChild(frame);
  return new Promise((resolve) => {
    frame.onload = () => resolve(frame);
  });
};

export class FrameProvider {
  // eslint-disable-next-line class-methods-use-this
  async appendFrame(selector) {
    return appendFrame(selector);
  }
}
