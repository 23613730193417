/* eslint-disable no-param-reassign */
function setMaxHeight(target) {
  const maxHeight = target.height;
  target.style['max-height'] = `${maxHeight}`;
}

export function addTransition(target) {
  if (!target) {
    return;
  }

  target.style['-webkit-transition'] = 'max-height .5s ease-in-out';
  target.style['-o-transition:'] = 'max-height .5s ease-in-out';
  target.style.transition = 'max-height .5s ease-in-out';
}

function callAfterExpandCollapse(target) {
  if (!target) {
    return;
  }

  function transitionEventListener() {
    target.removeEventListener('transitionend', transitionEventListener);
    // remove "max-height" from the element's inline styles, so it can return to its initial value
    target.style['max-height'] = null;
  }

  // when the next css transition finishes (which should be the one we just triggered)
  target.addEventListener('transitionend', transitionEventListener);
}

export function collapse(target) {
  if (!target) {
    return;
  }

  const sectionHeight = target.height;

  // temporarily disable all css transitions, to not change make transition for max-height
  target.style.transition = '';

  // set max-height to fixed value (to not transitioned out of 'auto')
  requestAnimationFrame(() => {
    // add height and transition again
    target.style['max-height'] = `${sectionHeight}`;
    addTransition(target);

    // set max height to 0
    requestAnimationFrame(() => {
      target.style['max-height'] = 0;
    });
  });
}

export function expand(target) {
  if (!target) {
    return;
  }

  setMaxHeight(target);

  requestAnimationFrame(() => {
    callAfterExpandCollapse(target);
  });
}

/* eslint-enable no-param-reassign */
